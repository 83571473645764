class CategoryNavItem extends Component {
	constructor(el) {
		super(el);
		this.opener = this.$find('[data-opener]');
		this.subHolder = this.$find('[data-sub-holder]');
		this.addEventListeners();
	}

	toggleCubcats() {

		this.subHolder.classList.toggle('is-open');
		this.opener.classList.toggle('is-open');
	}

	addEventListeners() {
		this.opener && this.opener.addEventListener('click', () => this.toggleCubcats());
	}
}
